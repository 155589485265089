<template>
    <HomeLayout>
      <b-container
        class="d-flex flex-column align-items-start justify-content-start px-0"
      >
        <!-- table container row -->
        <b-row
          class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
          no-gutters
        >
          <b-card no-body class="shadow-sm border-0 table-container-card w-100">
            <!-- table actions row -->
            <b-row
              class="d-flex flex-row align-items-center justify-content-between w-100 my-1 my-md-2 px-lg-3 py-3"
              no-gutters
            >
              <!-- search input column -->
              <b-col
                class="d-flex flex-column align-items-start justify-content-center px-4 px-lg-0 py-2 py-lg-0"
                cols="12"
                md="4"
              >
                <!-- table search input -->
                <b-input-group class="search-input-group">
                  <b-form-input
                    class="search-input border-0 bg-light"
                    type="text"
                    placeholder="Search by title"
                    v-model="searchTable"
                    @keyup.native.enter="searchFn"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-row
                      class="d-flex flex-row align-items-center justify-content-center px-3 border-0 rounded-right bg-light"
                      no-gutters
                    >
                     
                      <b-icon
                        class="text-main-green"
                        icon="search"
                        size="lg"
                        aria-label="Search"
                      ></b-icon>
                    </b-row>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              
            </b-row>
            <!-- table row -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start px-3 w-100 table-container-row"
              no-gutters
            >
              <b-table
                ref="questionsTable"
                :bordered="false"
                :borderless="true"
                :hover="true"
                selectable
                show-empty
                select-mode="single"
                class="table-element"
                thead-class="table-header font-secondary"
                tbody-class="table-body"
                :busy="isLoading"
                :fields="VideoHeader"
                :items="VideoItems"
                @row-clicked="onTableRowClicked"
              >
                <!-- table busy state -->
                <template #table-busy>
                  <div class="text-center text-muted my-3">
                    <b-spinner class="align-middle"></b-spinner>
                    <span class="text-left mx-1">Loading...</span>
                  </div>
                </template>
                <!-- table empty state -->
                <template #empty="scope">
                  <b-row
                    class="d-flex flex-row align-items-center justfiy-content-center py-5"
                    no-gutters
                  >
                    <h6 class="text-center mb-0 mx-auto my-5">
                      {{ scope.emptyText }}
                    </h6>
                  </b-row>
                </template>
                <template #head>
         
        </template>
                <!-- VIdeo Name  -->
            <template #cell(videoName)="data">
                <b-col
                    class="d-flex flex-column align-items-start justify-content-center px-0"
                  >
                  <div class="d-flex align-items-center justify-content-center">
                    <b-card
                  :key="index"
                  overlay
                  class="
                    d-flex
                    flex-column 
                    align-items-center
                    justify-content-center
                    rounded-lg
                    mr-5
                    single-video-card
                    video-card
                    
                  "
                >
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-center
                      justify-content-center
                    
                    "
                    no-gutters
                  >
                  
                    <video
                      :src="data.value.videoURL"  
                      class="video-thumbnail "
                    
                    ></video>
                    <!-- preview video button -->
                    <b-button
                     
                      size="sm"
                      class="shadow-none  preview-btn"
                      pill
                      @click="openPreviewVideoModal(data.value, $event.target)"
                    >
                        
                    <b-icon
                        icon="play-fill"
                        aria-label="Preview Video"
                        class="play-fill"
                        style="height: 30px;width: 30px; color: white;"
                    ></b-icon>
                    </b-button>
                  </b-row>
                 
                </b-card>    
                  <div>
                 <div class="font-primary video-title">{{  data.value.videoTitle }}</div>
                 <!-- <div class="text-muted">you'll use to sign up for tutorials, labs, and studios. It also displays your lecture timetable to help you avoid choosing a tutorial</div> -->
              </div>
            </div>
                </b-col>
            </template>
               
               
              </b-table>
            </b-row>
            <!-- table controls -->
            <!-- safari fix (flex-shrink-0) -->
            <b-row
              class="d-flex flex-row align-items-center justify-content-center justify-content-md-end flex-shrink-0 px-3 py-2 w-100"
              no-gutters
            >
              <!-- pages per page control -->
              <!-- <b-col
                class="d-flex flex-column align-items-center align-items-sm-start align-items-md-end align-items-xl-start justify-content-center py-2 py-md-1"
                cols="12"
                sm="6"
                lg="5"
                xl="2"
              >
                <b-form-group
                  label="Per page"
                  label-cols="12"
                  label-cols-sm="6"
                  label-align="left"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0 w-100"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    class="per-page-select border-1 border-main-green py-0"
                    :options="pageOptions"
                  ></b-form-select>
                </b-form-group>
              </b-col> -->
              <!-- pagination controls -->
              <!-- <b-col
                class="d-flex flex-column align-items-center align-items-sm-end justify-content-center py-2 py-md-1"
                cols="12"
                sm="6"
                lg="4"
                xl="5"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="right"
                  size="md"
                  limit="4"
                  pills
                  class="table-pagination"
                  page-class="custom-page-item"
                  ellipsis-class="custom-page-item"
                  first-class="custom-page-item"
                  last-class="custom-page-item"
                  next-class="custom-page-item"
                  prev-class="custom-page-item"
                >
                  <template #first-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-left"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #prev-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-double-left"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #next-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-double-right"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                  <template #last-text>
                    <b-icon
                      class="text-main-green"
                      icon="chevron-right"
                      size="sm"
                      aria-label="Plus"
                    ></b-icon>
                  </template>
                </b-pagination>
              </b-col> -->
            </b-row>
          </b-card>
        
        </b-row>
      </b-container>
      <PreviewModal
        :isShow="isShowPreviewModal"
        @onClose="onClosePreviewVideoModal"
        :itemContent="videoArrItems"
       
      />
    </HomeLayout>
  </template>
  
  <script>
  // @ is an alias to /src
  import HomeLayout from "@/layout/HomeLayout";
import PreviewModal from "@/components/SupportCenter/PreviewModal";
  // services
  import { GetAllVideos, SearchVideos } from "@/services/howtovideos.service";
  import {mapGetters} from "vuex";
  
  export default {
    name: "SupportCenter",
    components: {
      HomeLayout,
      PreviewModal
    },
    data() {
      return {
      videoID : null,
      videoTitle: null,
      previewImage: null,
      isShowPreviewModal: false,
      modalThatBtn: null,
      modalShow: false,
        searchTable: null,
        isLoading: false,
        videothumbnailloader: true,
        VideoHeader: [
          {
            key: "videoName",
            label: "",
          },
         
        ],
        VideoItems: [
         
        ],
        videoArrItems : [],
        title: this.$route.query.title
        // table pagination controls
        // currentPage: 1,
        // perPage: 5,
        // pageOptions: [5, 10, 15, 20],
        // totalRows: 0,
        
      };
    },
    async mounted() {
      await this.initFn();
    
    },
    watch: {
      searchTable() {
        this.searchFn();
        
      },
      // perPage() {
      //   this.initFn();
      // },
      // currentPage() {
      //   this.initFn();
      // },
    },
    methods: {
     
      async initFn() {
        try {
          if(this.title == null){
             // if (val) this.currentPage = val;
          this.isLoading = true;
          let { data } = await GetAllVideos();
          // this.totalRows = data.results.total;
          
          this.VideoItems = data.map((x) => {
            return {
              videoName: {
                videoTitle: x.title,
                videoURL: x.videoURL
              
              },
             
            };
          });
          this.isLoading = false;
          }else{
            this.VideoItems = [];
          this.isLoading = true;
          let { data } = await SearchVideos({ title: this.title });
          console.log(data)
          this.VideoItems = data.map((x) => {
            return {
              videoName: {
                videoTitle: x.title,
                videoURL: x.videoURL
              
              },
            };
          });
          this.isLoading = false;
          }
         
        } catch (error) {
          this.isLoading = false;
          console.log(error);
        }
      },
      async searchVideos() {
        try {
          this.VideoItems = [];
          this.isLoading = true;
          let { data } = await SearchVideos({ title: this.searchTable });
          console.log(data)
          this.VideoItems = data.map((x) => {
            return {
              videoName: {
                videoTitle: x.title,
                videoURL: x.videoURL
              
              },
            };
          });
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.log(error);
        }
      },
      searchFn() {
     
        if (this.searchTable && this.searchTable.length > 0) {
          // call the init function to load the data based on the search query
          // pass 1 into the function to reset the current page to 1, before executing the search to ensure the search doesn't get hindered by the pagination
          this.searchVideos();
        }else{
          this.VideoItems = [];
          this.initFn();
        }
      },
    
     
    
     
      onTableRowClicked(row) {
       
        this.openPreviewVideoModal(row.videoName)
      },
    
      openPreviewVideoModal(item, button) {
        
      this.isShowPreviewModal = true;
      this.videoArrItems = item;
      this.modalThatBtn = button;
      this.$root.$emit("bv::show::modal", "preview-video-row-modal", button);
    },
    onClosePreviewVideoModal() {
      this.isShowPreviewModal = false;  
      
      
    },
    initfn(){
      this.initFn();
    }
    },
    computed: {
      ...mapGetters({
        getUserRole: "auth/getUserRole"
      }),
    }
  };
  </script>
  
  <style scoped>
  /* table card */
  .video-card{
    height: 100px;
    width: 130px;
    padding: 0px;
 
  }
  .video-container {
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 0.75rem; /* Rounded corners */
  align-content: center;
  align-items: center;
  
}

.video-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
 
}
  .video-title{
  font-weight: 400;
  font-size: 16px;
  
  }
  .preview-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  box-shadow: 0px 2px 8px var(--shadow-soft);
  border: 2px solid #1E7FC0;
  align-content: center;
  align-items: center;
  display: flex;
  color: #0B9B7D;
  background-color: #0B9B7D;
  
}
  .table-card-row {
    height: 100%;
  }
  
  .table-card-row .table-container-card {
    background-color: var(--white);
    width: 100%;
    min-height: 560px;
    border-radius: 0;
    border: 1px solid var(--light);
  }
  
  /* table actions row */
  .table-card-row .search-input-group {
    box-shadow: none;
    height: 50px;
    min-width: 200px;
  }
  
  .table-card-row .search-input-group .search-input {
    box-shadow: none;
    height: 50px;
    /* min-width: 180px; */
  }
  
  .table-card-row .search-input-group .search-input::placeholder {
    color: #0B9B7D;
  }
  
  .table-card-row .filter-dropdown,
  .table-card-row .add-question-btn {
    height: 45px;
  }
  
  .table-card-row .add-question-btn:hover {
    background: #0B9B7D;
    box-shadow: inset 0px 0px 10px #ffffff;
  }
  
  .table-card-row .filter-dropdown >>> .filter-dropdown-menu {
    height: auto;
    min-width: 200px;
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  
  .table-card-row
    .filter-dropdown
    >>> .filter-dropdown-menu
    button.dropdown-item.active,
  .table-card-row
    .filter-dropdown
    >>> .filter-dropdown-menu
    button.dropdown-item:active {
    background-color: var(--light);
  }
  
  /* table container */
  .table-container-row {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
  }
  
  /* scrollbar size fix for webkit browsers (chrome/safari) */
  .table-container-row::-webkit-scrollbar {
    height: 0.5rem;
    scrollbar-width: thin;
  }
  
  .table-container-row::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f0f0f0;
  }
  
  .table-container-row::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
  }
  
  .table-container-row .table-element {
    min-width: 1000px;
  }
  
  /* table element css */
  .table-element >>> .table-header {
    /* background-color: #f7f7ff; */
    background-color: transparent;
  }
  
  .table-element >>> .table-header th {
    color: var(--prime-gray);
    font-size: 0.8rem;
    font-weight: 400;
    border-color: transparent;
  }
  
  .table-element >>> .table-body td {
    vertical-align: middle;
  }
  
  .table-element >>> .table-body tr:nth-child(even) {
    vertical-align: middle;
    background-color: var(--light);
  }
  
  .table-element >>> .table-body .main-text {
    font-size: 0.9rem;
  }
  
  .table-element >>> .table-body .sub-text {
    font-size: 0.7rem;
  }
  
  /* question row */
  .table-element >>> .table-body .question-row {
    max-width: 550px;
  }
  
  /* actions row */
  .table-element >>> .table-body .actions-row {
    min-width: 140px;
  }
  
  /* more icon button (default state) */
  .table-element >>> .table-body .more-btn {
    width: 35px;
    height: 35px;
    padding: 7px;
    background-color: #0B9B7D;
    transition: background-color 200ms ease-in;
  }
  
  .table-element >>> .table-body .more-btn > .more-icon {
    color: var(--white);
    transition: color 200ms ease-in;
  }
  
  /* more icon button (hover state) */
  .table-element >>> .table-body .more-btn:hover {
    background-color: var(--prime-gray);
  }
  
  .table-element >>> .table-body .more-btn:hover > .more-icon {
    color: var(--white);
  }
  
  /* table question action buttons */
  /* edit icon button (default state) */
  .table-element >>> .table-body .edit-btn {
    width: 40px;
    height: 40px;
    padding: 7px;
    background-color: var(--white);
    transition: background-color 200ms ease-in;
  }
  
  .table-element >>> .table-body .edit-btn > .edit-icon {
    color: #0B9B7D;
    transition: color 200ms ease-in;
  }
  
  /* edit icon button (hover state) */
  .table-element >>> .table-body .edit-btn:hover {
    background-color: #0B9B7D;
  }
  
  .table-element >>> .table-body .edit-btn:hover > .edit-icon {
    color: var(--white);
  }
  
  /* delete icon button (default state) */
  .table-element >>> .table-body .delete-btn {
    width: 40px;
    height: 40px;
    background-color: var(--white);
    transition: background-color 200ms ease-in;
  }
  
  .table-element >>> .table-body .delete-btn > .delete-icon {
    color: var(--danger);
    transition: color 200ms ease-in;
  }
  
  /* delete icon button (hover state) */
  .table-element >>> .table-body .delete-btn:hover {
    background-color: var(--danger);
  }
  
  .table-element >>> .table-body .delete-btn:hover > .delete-icon {
    color: var(--white);
  }
  
  /* expand icon button (default state) */
  .table-element >>> .table-body .expand-btn {
    width: 40px;
    height: 40px;
    padding: 7px;
    background-color: var(--white);
    transition: background-color 200ms ease-in;
  }
  
  .table-element >>> .table-body .expand-btn > .expand-icon {
    color: var(--prime-gray);
    transition: color 200ms ease-in;
  }
  
  /* expand icon button (hover state) */
  .table-element >>> .table-body .expand-btn:hover {
    background-color: var(--prime-gray);
  }
  
  .table-element >>> .table-body .expand-btn:hover > .expand-icon {
    color: var(--white);
  }
  
  .table-element >>> .table-body .b-table-details {
    background-color: #fff;
  }
  
  .table-element >>> .table-body .b-table-details:hover {
    cursor: auto;
    background-color: #fff;
  }
  
  /* table per page selector */
  .per-page-select {
    cursor: pointer;
    min-width: 65px;
  }
  
  /* table pagination */
  .table-pagination >>> .custom-page-item button,
  .table-pagination >>> .custom-page-item span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    border-color: transparent;
    border-radius: 0;
    color: var(--dark);
    padding: 0.5rem 0.6rem;
    font-size: 18px;
    height: 20px;
    transition: all 150ms ease-in;
  }
  
  .table-pagination >>> .custom-page-item button:hover {
    color: #0B9B7D;
    /* border-color: var(--secondary); */
    background-color: var(--light);
  }
  
  .table-pagination >>> .custom-page-item.active button,
  .table-pagination >>> .custom-page-item.active span {
    color: #0B9B7D;
    border: none;
    border-color: transparent;
    font-weight: bold;
    background-color: transparent;
  }
  
  .table-pagination >>> .custom-page-item.disabled button,
  .table-pagination >>> .custom-page-item.disabled span {
    opacity: 0.5;
    border-color: transparent;
    background-color: transparent;
  }
  
  /* Small devices (landscape phones, less than 768px) */
  /* @media (max-width: 767.98px) {
    .table-card-row .search-input-group {
      max-width: none;
    }
  } */
  </style>
  