<template>
    <!-- preview modal -->
    <b-modal
      id="preview-video-row-modal"
      ref="previewItemModal"
      :title="
        itemContent && itemContent.videoTitle
          ? itemContent.videoTitle
          : 'Video Preview'
      "
      size="xl"
      body-bg-variant="dark"
      header-class="font-primary"
      header-bg-variant="dark"
      header-text-variant="white"
      header-border-variant="dark"
      hide-footer
      centered
      @hide = "reloadfn"
    >
      <!-- custom close icon -->
      <template v-slot:modal-header-close>
                            <b-icon
                            class=" mr-2" style="height: 30px; width: 30px;color: white;"
                            icon="x"
                            size="lg"
                            aria-label="Search"
                            @click="closeModal()"
                          ></b-icon>
      </template>
      <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100"
        no-gutters
        v-if="itemContent && itemContent.videoURL"
      >
        <video
        
          width="100%"
          height="auto"
          controls
          :src="itemContent.videoURL"
        ></video>
      </b-row>
    </b-modal>
  </template>
  
  <script>
  // services
  
  export default {
    name: "PreviewModal",
    props: {
      isShow: {
        default: false,
        type: Boolean,
      },
      itemContent: {
        default: null,
       
      },
    },
    methods: {
      closeModal() {
        
        this.$emit("onClose");
      },
      reloadfn(){
        this.$emit("reloadFn");
      }
      
    },
  };
  </script>